// CONSTANTS
$black: rgba(7, 13, 28, 1);
$white: rgb(248, 248, 248);
$steel-blue: rgb(70, 130, 180);
$gray: rgb(240, 240, 240);
$spacer: 40px;

// FONTS
$font-size: 18px;
$font-weight: 400;
$bold: $font-weight * 2;

$theme-colors: (
  'primary': #3b5998,
  'secondary': #9ba9c5,
  'light': #5e94ac,
  'dark': #213358,
  'info': #5d81cfe1,
  'success': #a7cc79,
  'danger': #cc6b6e,
  'warning': #ebca6f,
  'black': $black,
  'white': $white,
  'steel-blue': $steel-blue,
  'body': $black,
);

$font-primary: 'Heebo', 'sans-serif';
$font-primary-url: 'https://fonts.googleapis.com/css?family=Heebo:100';
@import url($font-primary-url);

$font-secondary: 'Heebo', 'sans-serif';
$font-secondary-url: 'https://fonts.googleapis.com/css?family=Heebo:100';
@import url($font-secondary-url);

@font-face {
  font-family: $font-primary;
  src: url($font-primary-url);
}

// Basic Styling (DO NOT MODIFY)
body {
  background-color: map-get($map: $theme-colors, $key: 'white') !important;
  color: map-get($map: $theme-colors, $key: 'black') !important;
  font-family: $font-primary !important;
  font-size: $font-size !important;
  input {
    font-family: $font-secondary !important;
    font-style: oblique !important;
    font-weight: $bold !important;
  }

  select {
    font-family: $font-secondary !important;
    font-style: oblique !important;
    font-weight: $bold !important;
  }

  .font-primary {
    font-family: $font-primary !important;
  }

  .font-secondary {
    font-family: $font-secondary !important;
  }

  #ReactApp {
    min-height: 100vh;
  }
  #main {
    min-height: 100vh;
  }
}

table.table.table-striped tr.found td {
  background-color: #fdfafa;
}

.pointer {
  cursor: pointer;
}

@import '~bootstrap/scss/bootstrap.scss';
